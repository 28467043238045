body {
  margin: 0;
  font-family: "Ubuntu Mono", sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  background-color: black;
  color: #fff;
  scroll-behavior: smooth;
  overflow-x: hidden;
}

article p {
  font-size: 20px;
}

article img {
  overflow: hidden;
  background-size: cover;
  background-position: center;
  background-repeat: no-repeat;
}

/* --- Nav --- */
nav {
  top: 0;
  width: 100%;
  display: flex;
  justify-content: space-around;
  align-items: center;
  text-transform: uppercase;
  min-height: 10vh;
  background-color: #000000;
  font-family: "Ubuntu Mono", sans-serif;
  font-weight: 400;
  border-bottom: black 0.5px;
  z-index: 5;
  position: fixed;
}

.logo {
  color: #fff;
  text-transform: uppercase;
  letter-spacing: 3px;
  font-size: 24px;
}

.nav-links {
  display: flex;
  justify-content: space-around;
  width: 20%;
}

.nav-links li {
  list-style: none;
}

.nav-links a {
  color: #f4f4f4;
  text-decoration: none;
  letter-spacing: 1px;
  font-weight: bold;
  font-size: 17px;
}

.nav-links :hover {
  color: rgb(185, 183, 183);
  transition: 0.3s ease;
}

.burger {
  display: none;
}

.burger div {
  width: 25px;
  height: 3px;
  border-radius: 8px;
  background-color: #f4f4f4;
  margin: 5px;
  transition: all 0.3s ease;
}

@media screen and (max-width: 1024px) {
  .nav-links {
    width: 30%;
  }
}

@media screen and(max-width: 600px) {
}

@media screen and (max-width: 768px) {
  body {
    overflow-x: hidden;
  }

  .nav-links {
    position: fixed;
    right: 0px;
    height: 92vh;
    top: 8vh;
    background-color: rgba(0, 0, 0, 0.207);
    display: flex;
    flex-direction: column;
    align-items: center;
    width: 50%;
    transform: translateX(100%);
    transition: transform 0.2s ease-in;
  }

  .nav-links li {
    opacity: 0;
  }

  .burger {
    display: block;
    cursor: pointer;
  }
}

.nav-active {
  transform: translateX(0%);
}

@keyframes navLinkFade {
  from {
    opacity: 0;
    transform: translateX(50px);
  }
  to {
    opacity: 1;
    transform: translateX(0);
  }
}

.toggle .line1 {
  transform: rotate(-45deg) translate(-5px, 6px);
}

.toggle .line2 {
  opacity: 0;
}

.toggle .line3 {
  transform: rotate(45deg) translate(-5px, -6px);
}

.foreground {
  z-index: 100;
  justify-content: space-around;
}
/* background */

@keyframes move {
  100% {
    transform: translate3d(0, 0, 1px) rotate(360deg);
  }
}

@keyframes move {
  100% {
    transform: translate3d(0, 0, 1px) rotate(360deg);
  }
}

.background {
  width: 100vw;
  height: 100vh;
  background: #000000;
  overflow-x: hidden;
  max-width: 100%;
}

.background span {
  width: 2vmin;
  height: 2vmin;
  border-radius: 3vmin;
  backface-visibility: hidden;
  position: absolute;
  animation: move;
  animation-duration: 31;
  animation-timing-function: linear;
  animation-iteration-count: infinite;
}

.background span:nth-child(0) {
  color: #00364a;
  top: 52%;
  left: 94%;
  animation-duration: 21s;
  animation-delay: -167s;
  transform-origin: 22vw 4vh;
  box-shadow: 6vmin 0 0.839119162112292vmin currentColor;
}
.background span:nth-child(1) {
  color: #00364a;
  top: 32%;
  left: 7%;
  animation-duration: 168s;
  animation-delay: -122s;
  transform-origin: 9vw 21vh;
  box-shadow: -6vmin 0 0.7975705851108501vmin currentColor;
}
.background span:nth-child(2) {
  color: #00c7fc;
  top: 95%;
  left: 78%;
  animation-duration: 179s;
  animation-delay: -143s;
  transform-origin: 6vw 4vh;
  box-shadow: 6vmin 0 1.0446165351381156vmin currentColor;
}
.background span:nth-child(3) {
  color: #00c7fc;
  top: 7%;
  left: 72%;
  animation-duration: 16s;
  animation-delay: -126s;
  transform-origin: -24vw -4vh;
  box-shadow: -6vmin 0 0.7947139795626663vmin currentColor;
}
.background span:nth-child(4) {
  color: #00c7fc;
  top: 84%;
  left: 39%;
  animation-duration: 177s;
  animation-delay: -30s;
  transform-origin: -3vw -7vh;
  box-shadow: 6vmin 0 1.3586718008508112vmin currentColor;
}
.background span:nth-child(5) {
  color: #00c7fc;
  top: 83%;
  left: 77%;
  animation-duration: 131s;
  animation-delay: -94s;
  transform-origin: -8vw -19vh;
  box-shadow: 6vmin 0 1.6329473569059654vmin currentColor;
}
.background span:nth-child(6) {
  color: #00364a;
  top: 94%;
  left: 49%;
  animation-duration: 105s;
  animation-delay: -183s;
  transform-origin: -1vw -16vh;
  box-shadow: -6vmin 0 0.9522901495451581vmin currentColor;
}
.background span:nth-child(7) {
  color: #ffffff;
  top: 65%;
  left: 34%;
  animation-duration: 99s;
  animation-delay: -82s;
  transform-origin: -10vw 16vh;
  box-shadow: -6vmin 0 1.6417394072057256vmin currentColor;
}
.background span:nth-child(8) {
  color: #ffffff;
  top: 52%;
  left: 39%;
  animation-duration: 152s;
  animation-delay: -104s;
  transform-origin: 21vw 14vh;
  box-shadow: 6vmin 0 0.8906834168098162vmin currentColor;
}
.background span:nth-child(9) {
  color: #00c7fc;
  top: 25%;
  left: 33%;
  animation-duration: 24s;
  animation-delay: -17s;
  transform-origin: -23vw -3vh;
  box-shadow: 6vmin 0 1.4123058714624452vmin currentColor;
}
.background span:nth-child(10) {
  color: #ffffff;
  top: 29%;
  left: 12%;
  animation-duration: 109s;
  animation-delay: -19s;
  transform-origin: -20vw 13vh;
  box-shadow: -6vmin 0 0.8597369610195814vmin currentColor;
}
.background span:nth-child(11) {
  color: #ffffff;
  top: 28%;
  left: 57%;
  animation-duration: 124s;
  animation-delay: -131s;
  transform-origin: 1vw 25vh;
  box-shadow: 6vmin 0 1.1976698388616058vmin currentColor;
}
.background span:nth-child(12) {
  color: #ffffff;
  top: 48%;
  left: 50%;
  animation-duration: 49s;
  animation-delay: -103s;
  transform-origin: 16vw 4vh;
  box-shadow: 6vmin 0 1.73169443951386vmin currentColor;
}
.background span:nth-child(13) {
  color: #ffffff;
  top: 70%;
  left: 9%;
  animation-duration: 169s;
  animation-delay: -102s;
  transform-origin: 11vw 21vh;
  box-shadow: -6vmin 0 1.6737939282243042vmin currentColor;
}
.background span:nth-child(14) {
  color: #ffffff;
  top: 19%;
  left: 13%;
  animation-duration: 194s;
  animation-delay: -123s;
  transform-origin: -17vw 25vh;
  box-shadow: -6vmin 0 1.2867244007965248vmin currentColor;
}
.background span:nth-child(15) {
  color: #00364a;
  top: 52%;
  left: 35%;
  animation-duration: 25s;
  animation-delay: -94s;
  transform-origin: 6vw 3vh;
  box-shadow: 6vmin 0 1.1680596289168739vmin currentColor;
}
.background span:nth-child(16) {
  color: #00c7fc;
  top: 68%;
  left: 3%;
  animation-duration: 34s;
  animation-delay: -37s;
  transform-origin: 5vw 23vh;
  box-shadow: -6vmin 0 1.314583752099992vmin currentColor;
}
.background span:nth-child(17) {
  color: #ffffff;
  top: 56%;
  left: 57%;
  animation-duration: 131s;
  animation-delay: -38s;
  transform-origin: -1vw -19vh;
  box-shadow: 6vmin 0 1.1839956398416442vmin currentColor;
}
.background span:nth-child(18) {
  color: #00364a;
  top: 94%;
  left: 90%;
  animation-duration: 121s;
  animation-delay: -173s;
  transform-origin: 17vw 3vh;
  box-shadow: -6vmin 0 0.949435280504481vmin currentColor;
}
.background span:nth-child(19) {
  color: #00c7fc;
  top: 47%;
  left: 79%;
  animation-duration: 45s;
  animation-delay: -107s;
  transform-origin: 19vw -6vh;
  box-shadow: -6vmin 0 1.366299741010043vmin currentColor;
}
.background span:nth-child(20) {
  color: #00c7fc;
  top: 76%;
  left: 23%;
  animation-duration: 49s;
  animation-delay: -37s;
  transform-origin: -3vw 24vh;
  box-shadow: 6vmin 0 1.2673422187119647vmin currentColor;
}
.background span:nth-child(21) {
  color: #00364a;
  top: 38%;
  left: 42%;
  animation-duration: 138s;
  animation-delay: -154s;
  transform-origin: 23vw 0vh;
  box-shadow: -6vmin 0 0.8007773650406078vmin currentColor;
}
.background span:nth-child(22) {
  color: #00c7fc;
  top: 50%;
  left: 98%;
  animation-duration: 176s;
  animation-delay: -158s;
  transform-origin: 15vw 0vh;
  box-shadow: 6vmin 0 1.6959625035308816vmin currentColor;
}
.background span:nth-child(23) {
  color: #ffffff;
  top: 85%;
  left: 26%;
  animation-duration: 10s;
  animation-delay: -118s;
  transform-origin: 15vw 20vh;
  box-shadow: 6vmin 0 0.9568155939244252vmin currentColor;
}
.background span:nth-child(24) {
  color: #ffffff;
  top: 94%;
  left: 70%;
  animation-duration: 132s;
  animation-delay: -146s;
  transform-origin: 1vw -13vh;
  box-shadow: -6vmin 0 0.8895756126004829vmin currentColor;
}
.background span:nth-child(25) {
  color: #ffffff;
  top: 57%;
  left: 18%;
  animation-duration: 92s;
  animation-delay: -175s;
  transform-origin: 24vw 20vh;
  box-shadow: 6vmin 0 0.8081060009266187vmin currentColor;
}
.background span:nth-child(26) {
  color: #ffffff;
  top: 16%;
  left: 9%;
  animation-duration: 182s;
  animation-delay: -153s;
  transform-origin: -16vw -1vh;
  box-shadow: -6vmin 0 1.1071044396627971vmin currentColor;
}
.background span:nth-child(27) {
  color: #ffffff;
  top: 69%;
  left: 23%;
  animation-duration: 84s;
  animation-delay: -182s;
  transform-origin: 10vw -5vh;
  box-shadow: 6vmin 0 1.5166322434140924vmin currentColor;
}
.background span:nth-child(28) {
  color: #ffffff;
  top: 12%;
  left: 52%;
  animation-duration: 101s;
  animation-delay: -142s;
  transform-origin: 5vw -20vh;
  box-shadow: -6vmin 0 1.22362333005556vmin currentColor;
}
.background span:nth-child(29) {
  color: #ffffff;
  top: 2%;
  left: 2%;
  animation-duration: 150s;
  animation-delay: -110s;
  transform-origin: -3vw -3vh;
  box-shadow: 6vmin 0 0.9567771406152331vmin currentColor;
}

.tracking-in-expand {
  -webkit-animation: tracking-in-expand 1.2s cubic-bezier(0.86, 0, 0.07, 1) both;
  animation: tracking-in-expand 1.2s cubic-bezier(0.86, 0, 0.07, 1) both;
}

/* ----------------------------------------------
 * Generated by Animista on 2022-4-16 21:7:45
 * Licensed under FreeBSD License.
 * See http://animista.net/license for more info. 
 * w: http://animista.net, t: @cssanimista
 * ---------------------------------------------- */

/**
 * ----------------------------------------
 * animation tracking-in-expand
 * ----------------------------------------
 */
@-webkit-keyframes tracking-in-expand {
  0% {
    letter-spacing: -0.5em;
    opacity: 0;
  }
  40% {
    opacity: 0.6;
  }
  100% {
    opacity: 1;
  }
}
@keyframes tracking-in-expand {
  0% {
    letter-spacing: -0.5em;
    opacity: 0;
  }
  40% {
    opacity: 0.6;
  }
  100% {
    opacity: 1;
  }
}

#delay {
  -webkit-animation: tracking-in-expand 1.2s cubic-bezier(0.86, 0, 0.07, 1) both;
  animation: tracking-in-expand 1.2s cubic-bezier(0.86, 0, 0.07, 1) both;
  animation-delay: 1.2s;
}

.rotate-in-center {
  -webkit-animation: rotate-in-center 1s cubic-bezier(0.25, 0.46, 0.45, 0.94)
    both;
  animation: rotate-in-center 1s cubic-bezier(0.25, 0.46, 0.45, 0.94) both;
  animation-delay: 1.6s;
}

/* ----------------------------------------------
 * Generated by Animista on 2022-4-16 21:15:57
 * Licensed under FreeBSD License.
 * See http://animista.net/license for more info. 
 * w: http://animista.net, t: @cssanimista
 * ---------------------------------------------- */

/**
 * ----------------------------------------
 * animation rotate-in-center
 * ----------------------------------------
 */
@-webkit-keyframes rotate-in-center {
  0% {
    -webkit-transform: rotate(-360deg);
    transform: rotate(-360deg);
    opacity: 1;
  }
  100% {
    -webkit-transform: rotate(0);
    transform: rotate(0);
    opacity: 1;
  }
}
@keyframes rotate-in-center {
  0% {
    -webkit-transform: rotate(-360deg);
    transform: rotate(-360deg);
    opacity: 0;
  }
  100% {
    -webkit-transform: rotate(0);
    transform: rotate(0);
    opacity: 1;
  }
}

.wobble-hor-bottom {
  -webkit-animation: wobble-hor-bottom 2s infinite both;
  animation: wobble-hor-bottom 2s infinite both;
}

/* ----------------------------------------------
 * Generated by Animista on 2022-4-17 17:20:20
 * Licensed under FreeBSD License.
 * See http://animista.net/license for more info. 
 * w: http://animista.net, t: @cssanimista
 * ---------------------------------------------- */

/**
 * ----------------------------------------
 * animation wobble-hor-bottom
 * ----------------------------------------
 */
@-webkit-keyframes wobble-hor-bottom {
  0%,
  100% {
    -webkit-transform: translateX(0%);
    transform: translateX(0%);
    -webkit-transform-origin: 50% 50%;
    transform-origin: 50% 50%;
  }
  15% {
    -webkit-transform: translateX(-30px) rotate(-6deg);
    transform: translateX(-30px) rotate(-6deg);
  }
  30% {
    -webkit-transform: translateX(15px) rotate(6deg);
    transform: translateX(15px) rotate(6deg);
  }
  45% {
    -webkit-transform: translateX(-15px) rotate(-3.6deg);
    transform: translateX(-15px) rotate(-3.6deg);
  }
  60% {
    -webkit-transform: translateX(9px) rotate(2.4deg);
    transform: translateX(9px) rotate(2.4deg);
  }
  75% {
    -webkit-transform: translateX(-6px) rotate(-1.2deg);
    transform: translateX(-6px) rotate(-1.2deg);
  }
}
@keyframes wobble-hor-bottom {
  0%,
  100% {
    -webkit-transform: translateX(0%);
    transform: translateX(0%);
    -webkit-transform-origin: 50% 50%;
    transform-origin: 50% 50%;
  }
  15% {
    -webkit-transform: translateX(-30px) rotate(-6deg);
    transform: translateX(-30px) rotate(-6deg);
  }
  30% {
    -webkit-transform: translateX(15px) rotate(6deg);
    transform: translateX(15px) rotate(6deg);
  }
  45% {
    -webkit-transform: translateX(-15px) rotate(-3.6deg);
    transform: translateX(-15px) rotate(-3.6deg);
  }
  60% {
    -webkit-transform: translateX(9px) rotate(2.4deg);
    transform: translateX(9px) rotate(2.4deg);
  }
  75% {
    -webkit-transform: translateX(-6px) rotate(-1.2deg);
    transform: translateX(-6px) rotate(-1.2deg);
  }
}

.r2d2 {
  max-height: 200px;
  max-width: 200px;
  z-index: 100;
}

.about-me {
  height: 100vh;
}

.portrait {
  max-height: 150px;
  max-width: 150px;
  z-index: 100;
}

.my-container {
  max-width: 960px;
  display: block;
  text-align: left;
}

@media (max-width: 900px) {
  .my-container {
    max-width: 440px;
  }
}

.tilt-in-fwd-tr {
  -webkit-animation: tilt-in-fwd-tr 1s cubic-bezier(0.25, 0.46, 0.45, 0.94) both;
  animation: tilt-in-fwd-tr 1s cubic-bezier(0.25, 0.46, 0.45, 0.94) both;
}

/* ----------------------------------------------
 * Generated by Animista on 2022-4-18 12:29:48
 * Licensed under FreeBSD License.
 * See http://animista.net/license for more info. 
 * w: http://animista.net, t: @cssanimista
 * ---------------------------------------------- */

/**
 * ----------------------------------------
 * animation tilt-in-fwd-tr
 * ----------------------------------------
 */
@-webkit-keyframes tilt-in-fwd-tr {
  0% {
    -webkit-transform: rotateY(20deg) rotateX(35deg) translate(300px, -300px)
      skew(-35deg, 10deg);
    transform: rotateY(20deg) rotateX(35deg) translate(300px, -300px)
      skew(-35deg, 10deg);
    opacity: 0;
  }
  100% {
    -webkit-transform: rotateY(0) rotateX(0deg) translate(0, 0) skew(0deg, 0deg);
    transform: rotateY(0) rotateX(0deg) translate(0, 0) skew(0deg, 0deg);
    opacity: 1;
  }
}
@keyframes tilt-in-fwd-tr {
  0% {
    -webkit-transform: rotateY(20deg) rotateX(35deg) translate(300px, -300px)
      skew(-35deg, 10deg);
    transform: rotateY(20deg) rotateX(35deg) translate(300px, -300px)
      skew(-35deg, 10deg);
    opacity: 0;
  }
  100% {
    -webkit-transform: rotateY(0) rotateX(0deg) translate(0, 0) skew(0deg, 0deg);
    transform: rotateY(0) rotateX(0deg) translate(0, 0) skew(0deg, 0deg);
    opacity: 1;
  }
}

.down-arrow {
  padding-top: 80px;
  text-transform: uppercase;
  letter-spacing: 3px;
}
.down-arrow .arrow {
  left: 50%;
  width: 24px;
  top: 85%;
  height: 24px;
  margin-left: -12px;
  border-left: 4px solid #fff;
  border-bottom: 4px solid #fff;
  -webkit-transform: rotate(-45deg);
  transform: rotate(-45deg);
  -webkit-animation: sdb04 2s infinite;
  animation: sdb04 2s infinite;
  box-sizing: border-box;
}

.arrow:hover {
  border-left: 4px solid rgb(147 197 253);
  border-bottom: 4px solid rgb(147 197 253);
  cursor: pointer;
  transition: 0.5s;
}

@-webkit-keyframes sdb04 {
  0% {
    -webkit-transform: rotate(-45deg) translate(0, 0);
  }

  20% {
    -webkit-transform: rotate(-45deg) translate(-10px, 10px);
  }

  40% {
    -webkit-transform: rotate(-45deg) translate(0, 0);
  }
}

@keyframes sdb04 {
  0% {
    transform: rotate(-45deg) translate(0, 0);
  }

  20% {
    transform: rotate(-45deg) translate(-10px, 10px);
  }

  40% {
    transform: rotate(-45deg) translate(0, 0);
  }
}
